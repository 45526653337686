import { SEARCH } from '@/queries';

export const useSearch = async (facilityId, checkInDate, checkOutDate) => {
  const facilitySearch = useFacilitySearch();

  try {
    const { data } = await useGraphQL(
      SEARCH,
      useTokens().access,
      {
        checkinDate: checkInDate || '',
        checkoutDate: checkOutDate || '',
        facility: (facilityId === 'all') ? null : facilityId
      }
    );

    facilitySearch.setSearchResults(data?.value?.data?.search);

    return {
      isLoading: false,
      error: null,
      results: data.value.data.search
    }
  } catch (error) {
    console.error('Error Getting Codes:', error);
  }
}
